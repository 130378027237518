const topLink = (() => {

  const pageTop = (document.getElementById("pageTop") != null);
  if (pageTop) {

    var Btn = document.querySelectorAll('.btn-more');
    var linkWraps = document.querySelectorAll('.link-wrap');

    linkWraps.forEach((linkWrap, i) => {
      gsap.set(Btn[i], { y: 50,});

      ScrollTrigger.create({
        trigger: linkWrap,
        start: 'top 80%', // 画面上部から80%の位置でトリガーを設定
        onEnter: () => {
          linkWrap.classList.add('act'); // actクラスを追加
          gsap.to(Btn[i],{
            y: 0,
            duration: 0.6,
          },);
        },
      });
    });

    var Btn2 = document.querySelectorAll('.js-linkBtn');
    Btn2.forEach((Btn2, i) => {
      ScrollTrigger.create({
        trigger: Btn2,
        start: 'top 80%', // 画面上部から80%の位置でトリガーを設定
        onEnter: () => {
          Btn2.classList.add('act');
        },
      });
    });


  }

})();
export default topLink;