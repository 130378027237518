import { scrollControlFunc, windowResize, comMain } from './index';

const loadFunc = (() => {

  let loadWrap = document.getElementById('jsLoadWrap');
  // scrollControlFunc.noScroll();
  windowResize();

  const startFunc = () => {
    loadWrap.classList.add('no-act');
    // topFirst();
    comMain();
    const pageTop = (document.getElementById("pageTop") != null);
    if (!pageTop) {
      scrollControlFunc.returnScroll();
    }
  }

  // document.addEventListener("DOMContentLoaded", () => {
  //   setTimeout(startFunc, 100)
  // });
  window.addEventListener("load", () => {
    setTimeout(startFunc, 100)
  });

})();
export default loadFunc;