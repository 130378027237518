const comScroll = (() => {

  const scrool = document.querySelectorAll('.js-comScroll');
  if (scrool.length > 0) {

    for (let i = 0; i < scrool.length; i++) {
      gsap.set(scrool[i], {
        x: 60,
      });

      gsap.to(scrool[i], {
        x: 0,
        repeat: 2,
        duration: 1.6,
        scrollTrigger: {
          trigger: scrool[i],
          start: 'top 80%'
        },
        onComplete: () => {
          gsap.to(scrool[i], {
            opacity: 0,
            duration: 0.5,
          });
        },
      });
    }

  }

})();
export default comScroll;