const tab = (() => {

	const pageType = (document.getElementById("pageType") != null);
	if (pageType) {


		const tabBtn = document.querySelectorAll('.tab-btn');
		const tabCnt = document.querySelectorAll('.tab-cnt');
		const iconStg = document.querySelector('.icon-storage');
		const iconHot = document.querySelector('.icon-hot');

		for(let i = 0; i < tabBtn.length; i++){
			tabBtn[i].addEventListener('click',(event) => {
				// 既存の show クラスの有無をチェック
				const isIconStgShowing = iconStg.classList.contains('show');
				// show クラスを追加または削除
				if (!isIconStgShowing) {
					iconStg.classList.add('show');
					iconHot.classList.add('show');
				} else {
					iconStg.classList.remove('show');
					iconHot.classList.remove('show');
				}
				
				for(let j = 0; j < tabBtn.length; j++){
					tabBtn[j].classList.remove("act");
					tabCnt[j].classList.remove("act");
				}
				tabBtn[i].classList.add("act");
				tabCnt[i].classList.add("act");
			})
		}





	}

	const pagePremiumplan = (document.getElementById("pagePremiumplan") != null);
	if (pagePremiumplan) {
        const tabMenus = document.querySelectorAll('.tab_menu_item');
        tabMenus.forEach((tabMenu) => {
            tabMenu.addEventListener('click', tabSwitch);
        })
	}
    function tabSwitch(e) {
      // クリックされた要素のデータ属性を取得
        const wrap = document.getElementById('tab_contents');
        const tabTargetData = e.currentTarget.dataset.tab;

        wrap.classList.remove('r_type', 'p_type', 'q_type');
        wrap.classList.add( tabTargetData );
    }

})();
export default tab;
// ____________________________________________________________________________
// ____________________________________________________________________________
// ____________________________________________________________________________
