import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css';

const topSlide = (() => {

	const pageTop = (document.getElementById("pageTop") != null);
  if (pageTop) {

    var topSwiper = new Swiper('.topSwiper', {
			effect: 'fade',
			fadeEffect: {
				crossFade: true,
			},
      loop: true,
			speed: 3000,
			autoplay: {
				delay: 6000,
				disableOnInteraction: false,
				waitForTransition: false,
			},
			allowTouchMove: false,
      spaceBetween: 0,
    });

	}

})();
export default topSlide;