const comMain = () => {

  const jsComSecMain = (document.getElementById("jsComSecMain") != null);
  if (jsComSecMain) {

    const jsComSecMain = document.getElementById("jsComSecMain")

    const actFunc = ()=>{
      jsComSecMain.classList.add('act')
    }
    
    setTimeout(actFunc,100)

  }
};

export default comMain;