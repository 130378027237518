const slide = (() => {

	const pageGallery = (document.getElementById("pageGallery") != null);
	if (pageGallery) {

		const thumb = document.querySelectorAll('.thumb-media');

		const switchThumb = (index) => {
			document.querySelector('.thumb-media-active').classList.remove('thumb-media-active');
			thumb[index].classList.add('thumb-media-active');
		}

		const mySwiper = new Swiper('.gallery-swiper', {
			effect: 'fade',
			fadeEffect: {
				crossFade: true,
			},
			loop: true,
			loopAdditionalSlides: 1,
			speed: 4000,
			autoplay: {
				delay: 8000,
				disableOnInteraction: false,
				waitForTransition: false,
			},
			followFinger: false,
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
			on: {
				afterInit: (swiper) => {
					thumb[swiper.realIndex].classList.add('thumb-media-active');
						for (let i = 0; i < thumb.length; i++) {
						thumb[i].onclick = () => {
							swiper.slideTo(i+2);
						};
					}
				},
				slideChange: (swiper) => {
					switchThumb(swiper.realIndex);
				},
			}
		});








	}

})();
export default slide;
// ____________________________________________________________________________
// ____________________________________________________________________________
// ____________________________________________________________________________