const access = (() => {

  const pageAccess = (document.getElementById("pageAccess") != null);
  if (pageAccess) {

    const routeButton1 = document.getElementById("routeButton1");
    const routeButton2 = document.getElementById("routeButton2");

    function openGoogleMaps1() {
      var destination = document.getElementById('destination1').value + "駅";
      var origin = '練馬春日町駅';
      var url = 'https://www.google.com/maps/dir/' + encodeURIComponent(origin) + '/' + encodeURIComponent(destination) + '/@?mode=transit';
      window.open(url, '_blank');
    }

    function openGoogleMaps2() {
      var destination = document.getElementById('destination2').value + "駅";
      var origin = '平和台駅';
      var url = 'https://www.google.com/maps/dir/' + encodeURIComponent(origin) + '/' + encodeURIComponent(destination) + '/@?mode=transit';
      window.open(url, '_blank');
    }
  
    
    routeButton1.addEventListener("click", openGoogleMaps1);
    routeButton2.addEventListener("click", openGoogleMaps2);


  }

})();
export default access;