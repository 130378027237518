const lifeinfo = (() => {

	const pageLocation = (document.getElementById("pageLocation") != null);
	if (pageLocation) {

		// $(".tab_label").on("click",function(){
		// 	var $th = $(this).index();
		// 	$(".tab_label").removeClass("active");
		// 	$(".tab_panel").removeClass("active");
		// 	$(this).addClass("active");
		// 	$(".tab_panel").eq($th).addClass("active");
		// });

		const tabBtn = document.querySelectorAll('.tab_label');
		const tabCnt = document.querySelectorAll('.tab_panel');

		for(let i = 0; i < tabBtn.length; i++){
			tabBtn[i].addEventListener('click',(event) => {
				for(let j = 0; j < tabBtn.length; j++){
					tabBtn[j].classList.remove("act");
					tabCnt[j].classList.remove("act");
				}
				tabBtn[i].classList.add("act");
				tabCnt[i].classList.add("act");
			})
		}



		// ____________________________________________________________________________
		// ____________________________________________________________________________
		// ____________________________________________________________________________
		function getCSV(){
			var req = new XMLHttpRequest();
			req.open("get", "csv/lifeinfo.csv", true); // アクセスするファイルを指定
			req.send(null);
			req.onload = function(){
				convertCSVtoArray(req.responseText); // 渡されるのは読み込んだCSVデータ
			}
		}
		getCSV(); //最初に実行される

		// ____________________________________________________________________________


		function convertCSVtoArray(str){
	
			var csvdata = str.split("\n"); // 改行を区切り文字として行を要素とした配列を生成
		
			var csvdatalist = []; // 最終的な二次元配列を入れるための配列
			for(var i = 0; i < csvdata.length; i++){
				csvdatalist[i] = csvdata[i].split(',');
			}
			
			// console.log(csvdatalist);
		
			var reCommercial = [];
			var rePublic = [];
			var reFinance = [];
			var reEducation = [];
			var rePark = [];			
			var reMedical = [];
			var reGourmet = [];
			var Facility = new Array(reCommercial, rePublic,  reFinance, reEducation, rePark, reMedical, reGourmet);
		
			for(i = 0; i < csvdatalist.length; i++){
				if ( csvdatalist[i][0].indexOf('商業施設') !== -1) {
					reCommercial.push(csvdata[i].split(','));
				}else if ( csvdatalist[i][0].indexOf('公共施設') !== -1) {
					rePublic.push(csvdata[i].split(','));
				}else if ( csvdatalist[i][0].indexOf('金融機関') !== -1) {
					reFinance.push(csvdata[i].split(','));
				}else if ( csvdatalist[i][0].indexOf('教育施設') !== -1) {
					reEducation.push(csvdata[i].split(','));
				}else if ( csvdatalist[i][0].indexOf('公園・文化・スポーツ') !== -1) {
					rePark.push(csvdata[i].split(','));
				}else if ( csvdatalist[i][0].indexOf('医療施設') !== -1) {
					reMedical.push(csvdata[i].split(','));
				}else if ( csvdatalist[i][0].indexOf('グルメ') !== -1) {
					reGourmet.push(csvdata[i].split(','));
				}
			}
			console.log(Facility);
			// console.log(reCommercial);
			// console.log(rePark);
			// console.log(reCulture);
			// console.log(reEducation);
			// console.log(rePublic);
			// console.log(reFinance);
			// console.log(reMedical);
		
			// ____________________________________________________________________________
			function outDataBox(){
				for(var j = 0; j < Facility.length; j++){
					var dataBox = document.getElementById('dataBox' + (j+1));
					var insertElement = '';
					for(i = 0; i < Facility[j].length; i++){
						insertElement += '<div class="data data' + j + '_' + i + '">';
						insertElement += '<div class="data_img"><img src="../assets/build/img/location/pins/' + String(j) + ('00' + (i+1)).slice(-2) + '.png"></div>' ;
						insertElement += '<div class="data_txt"><p class="data_txt1">' + Facility[j][i][1] + '</p>';
						insertElement += '<p class="data_txt2">' + Facility[j][i][2] + Facility[j][i][3] + '分（約' + Number(Facility[j][i][4]).toLocaleString() + 'm）</p>';
						insertElement += '</div></div>';
					}
					dataBox.innerHTML = insertElement;
				}
			}
			outDataBox();
			// ____________________________________________________________________________
			var map;
			var point;
			var marker = [];
			var infoWindow = [];
		
			 // 地図の作成
			map = new google.maps.Map(document.getElementById('map'), { // #sampleに地図を埋め込む
				center: new google.maps.LatLng(35.7533360740825, 139.64353852883545),
				zoom: 14 // 地図のズームを指定
			});
			point = new google.maps.Marker({ 
				position: new google.maps.LatLng(35.7533360740825, 139.64353852883545),
				map: map,
				icon: {
					url: '../assets/build/img/location/point.png',
					scaledSize : new google.maps.Size(100, 57)
				}
			});
			
		
			//生成済マーカーを順次すべて削除する
			function deleteMakers() {
				for (var i = 0; i < marker.length; i++) {
					marker[i].setMap(null);
				}
				marker = [];	//参照を開放
			}
		
			// マーカー毎の処理
			function tabBtn(tabNum) {
				deleteMakers();
				for (var i = 0; i < Facility[tabNum].length; i++) {
					var markerLatLng = new google.maps.LatLng({lat: Number(Facility[tabNum][i][5]), lng: Number(Facility[tabNum][i][6])}); // 緯度経度のデータ作成
					marker[i] = new google.maps.Marker({ // マーカーの追加
						position: markerLatLng, // マーカーを立てる位置を指定
						map: map // マーカーを立てる地図を指定
					});
					infoWindow[i] = new google.maps.InfoWindow({ // 吹き出しの追加
						content: '<div class="sample">' + Facility[tabNum][i][1] + '</div>' +  Facility[tabNum][i][2] + Facility[tabNum][i][3] + '分（約' + Number(Facility[tabNum][i][4]) + 'm）' // 吹き出しに表示する内容
					});
					marker[i].setOptions({// TAM 東京のマーカーのオプション設定
						icon: {
							url: '../assets/build/img/location/pins/' + ( String(tabNum) + ('00' + (i+1)).slice(-2) ) + '.png',// マーカーの画像を変更
							scaledSize: new google.maps.Size(24, 36)
						}
					});
					markerEvent(i,tabNum); // マーカーにクリックイベントを追加
				}
			}
		
			// マーカーにクリックイベントを追加
			function markerEvent(i,tabNum) {
				function mapMove() {
					for(var j = 0; j < infoWindow.length; j++){
						infoWindow[j].close() ;
					};
					infoWindow[i].open(map, marker[i]); // 吹き出しの表示
					var movePos = new google.maps.LatLng( Number(Facility[tabNum][i][5]), Number(Facility[tabNum][i][6]) ); // 地図の中心を移動 
					map.panTo(movePos); 
				}
				marker[i].addListener('click', function() { // マーカーをクリックしたとき
					mapMove();
				});
				// $('.data'+ tabNum +'_'+ i +'').click(function() {
				// 	mapMove();
				// });
				document.querySelector('.data'+ tabNum +'_'+ i +'').addEventListener('click', function() {
					mapMove();
				});
				
			}
		
			// ____________________________________________________________________________
			tabBtn(0);
		
			// $('.loc_tab li').click(function(){
			// 	var index = $(this).index();
			// 	$(".tab_label").removeClass("active");
			// 	$(".tab_panel").removeClass("active");
			// 	if(index == 2){
			// 		$("#lifeBtn2").addClass("active");
			// 		$("#panel2").addClass("active");
			// 		tabBtn(1);
			// 	}else if(index == 3){
			// 		$("#lifeBtn3").addClass("active");
			// 		$("#panel3").addClass("active");
			// 		tabBtn(2);
			// 	}else if(index == 4){
			// 		$("#lifeBtn4").addClass("active");
			// 		$("#panel4").addClass("active");
			// 		tabBtn(3);
			// 	}else if(index == 5){
			// 		$("#lifeBtn5").addClass("active");
			// 		$("#panel5").addClass("active");
			// 		tabBtn(4);
			// 	}else{
			// 		$("#lifeBtn1").addClass("active");
			// 		$("#panel1").addClass("active");
			// 		tabBtn(0);
			// 	}
			// });
		
			document.getElementById("lifeBtn1").onclick = function() {
				tabBtn(0);
			};
			document.getElementById("lifeBtn2").onclick = function() {
				tabBtn(1);
			};
			document.getElementById("lifeBtn3").onclick = function() {
				tabBtn(2);
			};
			document.getElementById("lifeBtn4").onclick = function() {
				tabBtn(3);
			};
			document.getElementById("lifeBtn5").onclick = function() {
				tabBtn(4);
			};
			document.getElementById("lifeBtn6").onclick = function() {
				tabBtn(5);
			};
			document.getElementById("lifeBtn7").onclick = function() {
				tabBtn(6);
			};
		
		}




	}

})();
export default lifeinfo;
// ____________________________________________________________________________
// ____________________________________________________________________________
// ____________________________________________________________________________