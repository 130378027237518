const merit = (() => {


  const pageMerit = (document.getElementById("pageMerit") != null);
  if (pageMerit) {

    
    window.addEventListener("load", apperHeight);
    window.addEventListener("resize", apperHeight);
    function apperHeight() {
      const img = document.querySelector(".js-appear");
      var imgHeight = img.offsetHeight;
      const wrapHeight = document.querySelector(".js-appearWrap");
      wrapHeight.style.height = `${imgHeight}px`;

    };


  }


  
  
  

})();
export default merit;