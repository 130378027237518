const navFunc = (() => {

  let nav = document.getElementById('jsNav');
  let navBtn = document.getElementById('jsNavBtn');
  // let gnav = document.getElementById('jsGnav');
  let blackBg = document.getElementById('jsBlackBg');
  
  const toggleClass = () =>{
    if( navBtn.classList.contains('act') == true ){
      nav.classList.remove('act');
      navBtn.classList.remove('act');
      // gnav.classList.remove('act');
      blackBg.classList.remove('act');
      navBtn.classList.remove('clr-blk');
    }else{
      nav.classList.add('act');
      navBtn.classList.add('act');
      // gnav.classList.add('act');
      blackBg.classList.add('act');
      navBtn.classList.add('clr-blk');
    }
  }

  navBtn.addEventListener('click', (event) => {
    // event.preventDefault();
    event.stopPropagation();
    toggleClass()
  });
  blackBg.addEventListener('click', (event) => {
    // event.preventDefault();
    event.stopPropagation();
    toggleClass()
  });

})();
export default navFunc;